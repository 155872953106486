import { type LoaderFunctionArgs } from '@remix-run/node';
import { redirect } from '@remix-run/react';

import { LoadingSpinner } from '../../src/components/LoadingSpinner';
import { venueUrlFrom } from '../../src/utils/router';

export const loader = async (action: LoaderFunctionArgs) => {
  const { venueId, gamePackId } = action.params;
  if (!venueId) return redirect('/');
  // Use the url incoming from the react-router "request" as the current url.
  const url = venueUrlFrom(action.request.url, venueId, gamePackId);
  return redirect(url.toString());
};

export default function Component() {
  return <LoadingSpinner />;
}
